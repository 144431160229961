import React, { Component } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import {
  TextField,
  Typography,
  Button,
  FormLabel,
  Toolbar,
  FormControl,
  Collapse,
  Select
} from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import { Tabs, Tab } from "@material-ui/core";
import AppBar from "@material-ui/core/AppBar";
import FormGroup from "@material-ui/core/FormGroup";
import TabContainer from "./shares/react_tab_container";
import language from "../layout/language";
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import ImageControl from "./shares/react_image_control";
import ServicePackageItem from "./service_package_item";

import classNames from "classnames";
import Utils from "../api/api";
import theme, { styles } from "../layout/theme";



// Require Editor JS files.
import "froala-editor/js/froala_editor.pkgd.min.js";
import "froala-editor/js/third_party/font_awesome.min.js";
import "froala-editor/js/third_party/ionicons.min.js";

// Require Editor CSS files.
import "froala-editor/css/froala_style.min.css";
import "froala-editor/css/froala_editor.pkgd.min.css";

// Require Font Awesome.
import "froala-editor/css/third_party/font_awesome.min.css";
import "froala-editor/css/third_party/ionicons.min.css";

import FroalaEditor from "react-froala-wysiwyg";

class ServiceDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      service: {
        titleVN: "",
        titleEN: "",
        //titleKR:'',
        summaryVN: "",
        summaryEN: "",
        //summaryKR:'',
        detailVN: "",
        detailEN: "",
        //detailKR:'',
        // price: 0,
        // priceSale: 0,
        image: [],
        time: "",
        urlVN: "",
        urlEN: "",
        descriptionVN: "",
        descriptionEN: "",
        //descriptionKR:'',
        dateModified: new Date(),
        packagesVN: [],
        packagesEN: [],
        getflyId: ''
      },
      error: {
        vn: false,
        en: false,
        //kr: false,
        titleVN_error: false,
        titleEN_error: false
        //titleKR_error: false,
      },
      failed: false,
      message: "",
      isUpdate: false,
      tab: 0,
      open: false,
      options: { empty: "empty" },
      source: null,
      oldIndex: null,
      getflyCourses: []
    };
  }
  componentDidMount() {
    var $this = this;
    var params = this.context.router.route.match.params;

    Utils.getData('getfly/schedule/categories','',function(data) {
      //console.log(data);
      if(data.status === 'success' && data.results.length) {
        $this.setState({getflyCourses: data.results});
      }
    });

    if (params.id !== undefined) {
      $this.setState({
          isUpdate: true
        },
        () => {
          Utils.getSingleData("services", params.id, function(res) {
            if (res.status === "success" && res.results !== undefined) {
              var data = {},
                item = res.results;
              for (var property in item) {
                if (item.hasOwnProperty(property)) {
                  //console.log(item[property]);
                  data[property] = item[property];
                }
              }
              data._id = item._id;
              if (data.urlVN === undefined) data.urlVN = "";
              if (data.urlEN === undefined) data.urlEN = "";
              if (data.image.length === 0) data.image.push("");
              // if (data.priceSale === undefined || data.priceSale === "")
              //   data.priceSale = data.price;
              if (data.dateModified === undefined) data.dateModified = new Date();
              if (data.packagesVN === undefined || data.packagesVN===null) data.packagesVN = [];
              if (data.packagesEN === undefined || data.packagesEN===null) data.packagesEN = [];
              data.packagesVN.map(x=>x.collapse===undefined ? x.collapse = true:x.collapse)
              data.packagesEN.map(x=>x.collapse===undefined ? x.collapse = true:x.collapse)
              $this.setState({ service: data }, function() {
                console.log($this.state.service);
              });
            }
          });
        }
      );
    }

    Utils.getBlockContents(function(options) {
      $this.setState({ options: options });
    });
  }

  onEditorStateChange(field, value) {
    var service = this.state.service,
      error = this.state.error;
    error[field + "_error"] = value == "";
    service[field] = value;
    if (field.indexOf("summary") !== -1) {
      var locale = field.replace("summary", "");
      var temporalDivElement = document.createElement("div");
      // Set the HTML content with the providen
      temporalDivElement.innerHTML = value;
      // Retrieve the text property of the element (cross-browser support)
      var text =
        temporalDivElement.textContent || temporalDivElement.innerText || "";
      service["description" + locale] = text.substring(0, 200);
    }

    this.setState({ service: service, error: error });
  }
  addMoreItem() {
    var service = this.state.service,
      data = service.image,
      l = data.length;
    if (l < 5) {
      data.push("");
      service.image = data;
      this.setState({ service: service }, function() {});
    }
  }
  removeItem(index) {
    //console.log(index);
    var service = this.state.service,
      data = service.image,
      l = data.length;
    if (l > 1) {
      data.splice(index, 1);
      service.image = data;
      this.setState({ service: service }, function() {});
    }
  }
  addMorePackage(flag) {
    var service = this.state.service,
      data = service['packages'+flag],
      obj = {
        _id: Date.now(),
        order: data.length+1,
        title: '',
        subtitle: '',
        summary: '',
        detail: '',
        detailTitle:'',
        isDisabled: false,
        price: 0,
        priceSale: 0,
        collapse: true
      };

    //console.log(service);

    data.push(obj);
    service['packages'+flag] = data;
    this.setState({ service: service }, function() {
      console.log(service);
    });
  }
  removePackage(index,flag) {
    var service = this.state.service,
      data = service['packages'+flag],
      l = data.length;
    if (l >= 1) {
      data.splice(index, 1);
      service['packages'+flag] = data;
      this.setState({ service: service }, function() {});
    }
  }
  handleImageChange(index, value) {
    //console.log(value);
    var $this = this,
      service = this.state.service;
    service.image[index] = value;
    this.setState({ service: service });
  }
  handleChangePackage(value,index,flag) {
    var $this = this,
      service = $this.state.service;
    service['packages'+flag][index] = value;
    this.setState({ service: service });
  }
  handleFieldChange(e) {
    var $this = this,
      service = this.state.service;
    service[e.target.id] = e.target.value;
    if (e.target.type == "textarea") {
      service[e.target.id] = e.target.value.substring(0, 200);
    }
    this.setState({ service: service });
  }
  handleValidate(e, flag) {
    var $this = this,
      service = this.state.service,
      obj = this.state.error;

    service[e.target.id] = e.target.value;
    //console.log(e.target.id);
    var p = e.target.id + "_error";
    //console.log(p);
    if (e.target.value.trim() == "" || e.target.value == null) {
      obj[p] = true;
    } else {
      obj[p] = false;
    }
    obj[flag] = false;

    if (e.target.id === "titleVN") {
      var urlVN = Utils.getFriendlyURL(e.target.value);
      service.urlVN = urlVN;
    }

    if (e.target.id === "titleEN") {
      var urlEN = Utils.getFriendlyURL(e.target.value);
      service.urlEN = urlEN;
    }


    if (e.target.id.indexOf("price") !== -1) {
      service[e.target.id] = parseFloat(e.target.value.trim());
    }

    this.setState({ service: service, error: obj }, () => {});
  }
  selectImg(index, value) {
    var $this = this,
      service = this.state.service;
    service.image[index] = value;
    this.setState({ service: service }, function() {
      $this.handleClose();
    });
  }
  _save() {
    var $this = this;
    var data = this.state.service;

    data.price = parseFloat(data.price);
    data.priceSale = parseFloat(data.priceSale);
    //console.log(data);
    if (this.state.isUpdate) {
      //data._id = this.state.id;
      Utils._update("service", data, function(res) {
        //console.log(res);
        if (res.status == "success") {
          $this.context.router.history.push("/service/");
        } else {
          $this.setState({
            failed: true,
            message: JSON.stringify(res.message)
          });
        }
      });
    } else {
      Utils._add("service", data, function(res) {
        if (res.status == "success") {
          $this.context.router.history.push("/service/");
        } else {
          $this.setState({
            failed: true,
            message: JSON.stringify(res.message)
          });
        }
      });
    }
  }
  handleSave(e, flag) {
    var params = this.context.router.route.match.params;

    if (params.id !== undefined && !this.state.isUpdate) {
      this.setState({ isUpdate: true });
    }
    var $this = this;
    var obj = this.state.error;
    if (obj[flag]) {
      return;
    } else {
      switch (flag) {
        case "vn":
          obj["titleVN_error"] = this.state.service.titleVN === "";
          obj["vn"] = obj["titleVN_error"];
          this.setState({ error: obj }, function() {
            if (!$this.state.error[flag]) $this._save();
          });
          break;
        case "en":
          obj["titleEN_error"] = this.state.service.titleEN === "";
          obj["en"] = obj["titleEN_error"];
          this.setState({ error: obj }, function() {
            if (!$this.state.error[flag]) $this._save();
          });
          break;
        // case 'kr':
        //   obj['titleKR_error'] = this.state.service.titleKR === '';
        //   obj['kr'] = obj['titleKR_error'];
        //   this.setState({error:obj},function(){
        //     if(!$this.state.error[flag]) $this._save();
        //   });
        //   break;
        default:
          return true;
      }
    }
  }
  handleCancel() {
    this.context.router.history.push("/service/");
  }
  handleChangeTab = (event, value) => {
    this.setState({ tab: value });
  }
  handleClickOpen = () => {
    this.setState({ open: true });
  }
  handleClose = () => {
    this.setState({ open: false });
  }
  togglePackage (index,flag) {
    let service = this.state.service,
        packages = service['packages'+flag];
    packages[index].collapse = !packages[index].collapse;
    service['packages'+flag] = packages;
    this.setState({
      service:service
    });
  }
  handleDragStart(e,item,index){
    var event = e;
    //event.dataTransfer.setData("text/plain", event.target.innerHTML);
    event.dataTransfer.effectAllowed = "move";
    this.setState({
      source: event.target,
      oldIndex: index
    });

  }
  handleDragOver(e){
    e.preventDefault();
    e.dataTransfer.dropEffect = "move";
  }
  handleDragEnd(e){
    //console.log(e);
  }
  handleDrop(e,item,index){
    //drop
    e.preventDefault();
    e.stopPropagation();
    var $this = this,
        service = this.state.service,
        data = this.state.service.images,
        old = data[$this.state.oldIndex];

    data.splice($this.state.oldIndex,1);
    data.splice(index,0,old);
    //console.log(data);
    // for(var i=0,len=data.length;i<len;i++){
    //   data[i].order = parseInt($this.state.activeStep*config.itemPerPage)+i
    // }
    service.images = data;

    this.setState({
      service:service
    },function(){
      console.log(service.images);
    });
  }
  handleDragStartPackage(e,item,index,flag){
    var event = e;
    //event.dataTransfer.setData("text/plain", event.target.innerHTML);
    event.dataTransfer.effectAllowed = "move";
    var obj = {};
    obj.source = event.target;
    obj.oldIndex = index;
    this.setState(obj);

  }
  handleDragOverPackage(e){
    e.preventDefault();
    e.dataTransfer.dropEffect = "move";
  }
  handleDragEndPackage(e){
    //console.log(e);
  }
  handleDropPackage(e,item,index,flag){
    //drop
    e.preventDefault();
    e.stopPropagation();
    var $this = this,
        service = this.state.service,
        data = this.state.service['packages'+flag],
        old = data[$this.state.oldIndex];

    data.splice($this.state.oldIndex,1);
    data.splice(index,0,old);

    service['packages'+flag] = data;

    this.setState({
      service:service
    },function(){

    });
  }
  render() {
    const classes = this.props.classes;
    const value = this.state.tab;
    const service = this.state.service;
    const editorConfigBasic = Utils.setupBasicEditor();
    const editorConfig = Utils.setupEditor("services", this.state.options);
    return (
      <div className="page-detail">
        <Toolbar className={classes.toolbar}>
          <Typography className={classes.heading} variant="h5">
            Thêm/Sửa dịch vụ
          </Typography>
        </Toolbar>
        <AppBar position="static" className={classes.subToolbar}>
          <Tabs value={value} onChange={this.handleChangeTab}>
            <Tab label="Tiếng Việt" />
            <Tab label="English" />
            {/* <Tab label="한국인"/> */}
          </Tabs>
        </AppBar>
        {value === 0 && (
          <TabContainer>
            <div className={classes.form} noValidate>
              <Typography
                type="body2"
                className={classNames(
                  classes.error,
                  !this.state.error.vn && classes.hide
                )}
              >
                {language.VN.error.require}
              </Typography>
              <Typography
                type="body2"
                className={classNames(
                  classes.error,
                  !this.state.failed && classes.hide
                )}
              >
                {this.state.message}
              </Typography>
              <TextField
                id="titleVN"
                label={language.VN.name}
                className={classes.textField}
                value={this.state.service.titleVN}
                onChange={event => this.handleValidate(event, "vn")}
                onBlur={event => this.handleValidate(event, "vn")}
                required={true}
                error={this.state.error.titleVN_error}
                margin="normal"
              />
              <TextField
                id="urlVN"
                label="Link [SEO]"
                className={classes.textField}
                value={this.state.service.urlVN}
                disabled={true}
                onChange={event => this.handleFieldChange(event)}
                margin="normal"
              />
              {
                this.state.getflyCourses!==undefined ? (
                  <FormControl className={classes.formControl}>
                    <Typography className={classes.label}>Đồng bộ với GetFly System:</Typography>
                    <Select native id='getflyId' value={this.state.service.getflyId} onChange={event => this.handleFieldChange(event,'vn')}>
                      <option value="" key={0}>...</option>
                      {this.state.getflyCourses.map((item, index) => {
                        return (
                          <option key={index + 1} value={item.category_id}>
                            {item.category_name}
                          </option>
                        );
                      })}
                    </Select>
                  </FormControl>
                ):''
              }
              <TextField
                id="time"
                label="Thời gian"
                className={classes.textField}
                value={this.state.service.time}
                onChange={event => this.handleFieldChange(event, "vn")}
                margin="normal"
              />
              <FormControl className={classes.formControl}>
                <Button
                  variant="contained"
                  color="primary"
                  aria-label="add"
                  size="small"
                  onClick={() => this.addMoreItem()}
                >
                  Thêm hình
                  <AddIcon />
                </Button>
              </FormControl>
              <div className="item-image-grid">
              {service.image.map((x, i) => {
                return (
                  <div key={i} className={classNames('item-image-wrapper',classes.drag_item)} draggable={true} onDragStart={(e)=>this.handleDragStart(e,x,i)} onDragOver={(e)=>this.handleDragOver(e)} onDragEnd={(e)=>this.handleDragEnd(e)} onDrop={(e)=>this.handleDrop(e,x,i)}>
                    <IconButton
                      color="secondary"
                      aria-label="delete"
                      size="small"
                      onClick={() => this.removeItem(i)}
                    >
                      <DeleteIcon />
                    </IconButton>
                    <ImageControl
                      className="small"
                      value={x}
                      handleChange={value => this.handleImageChange(i, value)}
                    />
                  </div>
                );
              })}
              </div>
              {/* <TextField
                id="price"
                type="number"
                label={language.VN.price}
                className={classes.textField}
                value={this.state.service.price}
                onChange={event => this.handleFieldChange(event)}
                margin="normal"
              />
              <TextField
                id="priceSale"
                type="number"
                label={language.VN.priceSale}
                className={classes.textField}
                value={this.state.service.priceSale}
                onChange={event => this.handleFieldChange(event)}
                margin="normal"
              /> */}
              <TextField
                id="descriptionVN"
                rows="5"
                multiline={true}
                label="Meta Description (max. length: 200 characters)"
                className={classes.textField}
                value={this.state.service.descriptionVN}
                onChange={event => this.handleFieldChange(event)}
                margin="normal"
              />
              <FormLabel className={classes.label_shrink}>
                {language.VN.summary}
              </FormLabel>
              <FroalaEditor
                tag="textarea"
                config={editorConfigBasic}
                model={this.state.service.summaryVN}
                onModelChange={value =>
                  this.onEditorStateChange("summaryVN", value)
                }
              />
              <FormLabel className={classes.label_shrink}>
                {language.VN.detail}
              </FormLabel>
              {
                this.state.options['empty']!=='empty' && (
                  <FroalaEditor
                    tag="textarea"
                    config={editorConfig}
                    model={this.state.service.detailVN}
                    onModelChange={value =>
                      this.onEditorStateChange("detailVN", value)
                    }
                  />
                )
              }
            </div>
            <div className={classes.form} noValidate>
              <FormGroup row>
                <FormControl className={classes.formControl}>
                  <FormLabel className={classes.label_shrink}>
                    Các gói dịch vụ
                  </FormLabel>
                  <Button
                    variant="contained"
                    color="primary"
                    aria-label="add"
                    size="small"
                    onClick={() => this.addMorePackage('VN')}
                  >
                    Thêm gói dịch vụ
                    <AddIcon />
                  </Button>
                </FormControl>
              </FormGroup>
              {service.packagesVN.map((x, i) => {
                return (
                  <FormGroup key={i} className={classNames(classes.packageItemWrapper,classes.drag_item)} draggable={true} onDragStart={(e)=>this.handleDragStartPackage(e,x,i,'VN')} onDragOver={(e)=>this.handleDragOverPackage(e,'VN')} onDragEnd={(e)=>this.handleDragEndPackage(e,'VN')} onDrop={(e)=>this.handleDropPackage(e,x,i,'VN')}>
                    <Button
                      color="default"
                      variant="contained"
                      aria-label="delete"
                      size="small"
                      onClick={() => this.togglePackage(i,'VN')}>
                    {x.collapse ? <ExpandLess /> : <ExpandMore />}
                    </Button>
                    <Collapse className={classes.packageItem} in={x.collapse} timeout="auto" unmountOnExit>
                      <IconButton
                        className={classes.packageItemDelete}
                        color="secondary"
                        aria-label="delete"
                        size="small"
                        onClick={() => this.removePackage(i,'VN')}
                      >
                        <DeleteIcon />
                      </IconButton>
                      <ServicePackageItem locale="VN" key={i} item={x} onChangeValue={(data)=>this.handleChangePackage(data,i,'VN')}></ServicePackageItem>
                    </Collapse>
                  </FormGroup>
                );
              })}
            </div>
            <div className={classes.form} noValidate>
              <FormGroup row className={classes.formRow}>
                <Button
                  color="default"
                  onClick={event => this.handleCancel(event)}
                >
                  {language.VN.btnCancel}
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={event => this.handleSave(event, "vn")}
                >
                  {language.VN.btnSave}
                </Button>
              </FormGroup>
            </div>
          </TabContainer>
        )}
        {value === 1 && (
          <TabContainer>
            <div className={classes.form} noValidate>
              <Typography
                type="body2"
                className={classNames(
                  classes.error,
                  !this.state.error.en && classes.hide
                )}
              >
                {language.EN.error.require}
              </Typography>
              <Typography
                type="body2"
                className={classNames(
                  classes.error,
                  !this.state.failed && classes.hide
                )}
              >
                {this.state.message}
              </Typography>
              <TextField
                id="titleEN"
                label={language.EN.name}
                className={classes.textField}
                value={this.state.service.titleEN}
                onChange={event => this.handleValidate(event, "en")}
                onBlur={event => this.handleValidate(event, "en")}
                required={true}
                error={this.state.error.titleEN_error}
                margin="normal"
              />
              <TextField
                id="urlEN"
                label="Link [SEO]"
                className={classes.textField}
                value={this.state.service.urlEN}
                disabled={true}
                onChange={event => this.handleFieldChange(event)}
                margin="normal"
              />
              <TextField
                id="descriptionEN"
                rows="5"
                multiline={true}
                label="Meta Description (max. length: 200 characters)"
                className={classes.textField}
                value={this.state.service.descriptionEN}
                onChange={event => this.handleFieldChange(event)}
                margin="normal"
              />
              <FormLabel className={classes.label_shrink}>
                {language.EN.summary}
              </FormLabel>
              <FroalaEditor
                tag="textarea"
                config={editorConfigBasic}
                model={this.state.service.summaryEN}
                onModelChange={value =>
                  this.onEditorStateChange("summaryEN", value)
                }
              />
              <FormLabel className={classes.label_shrink}>
                {language.EN.detail}
              </FormLabel>
              <FroalaEditor
                tag="textarea"
                config={editorConfig}
                model={this.state.service.detailEN}
                onModelChange={value =>
                  this.onEditorStateChange("detailEN", value)
                }
              />
            </div>
            <div className={classes.form} noValidate>
              <FormGroup row>
                <FormControl className={classes.formControl}>
                  <FormLabel className={classes.label_shrink}>
                    Service Packages
                  </FormLabel>
                  <Button
                    variant="contained"
                    color="primary"
                    aria-label="add"
                    size="small"
                    onClick={() => this.addMorePackage('EN')}
                  >
                    Add more package
                    <AddIcon />
                  </Button>
                </FormControl>
              </FormGroup>
              {service.packagesEN.map((x, i) => {
                return (
                  <FormGroup key={i} className={classNames(classes.packageItemWrapper,classes.drag_item)} draggable={true} onDragStart={(e)=>this.handleDragStartPackage(e,x,i,'EN')} onDragOver={(e)=>this.handleDragOverPackage(e,'EN')} onDragEnd={(e)=>this.handleDragEndPackage(e,'EN')} onDrop={(e)=>this.handleDropPackage(e,x,i,'EN')}>
                    <Button
                      color="default"
                      variant="contained"
                      aria-label="delete"
                      size="small"
                      onClick={() => this.togglePackage(i,'EN')}>
                    {x.collapse ? <ExpandLess /> : <ExpandMore />}
                    </Button>
                    <Collapse className={classes.packageItem} in={x.collapse} timeout="auto" unmountOnExit>
                      <IconButton
                        className={classes.packageItemDelete}
                        color="secondary"
                        aria-label="delete"
                        size="small"
                        onClick={() => this.removePackage(i,'EN')}
                      >
                        <DeleteIcon />
                      </IconButton>
                      <ServicePackageItem locale="EN" key={i} item={x} onChangeValue={(data)=>this.handleChangePackage(data,i,'EN')}></ServicePackageItem>
                    </Collapse>
                  </FormGroup>
                );
              })}
            </div>
            <div className={classes.form} noValidate>
              <FormGroup row className={classes.formRow}>
                <Button
                  color="default"
                  onClick={event => this.handleCancel(event)}
                >
                  {language.EN.btnCancel}
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={event => this.handleSave(event, "en")}
                >
                  {language.EN.btnSave}
                </Button>
              </FormGroup>
            </div>
          </TabContainer>
        )}
        {/* {value === 2 && <TabContainer>
          <form className={classes.form} noValidate>
            <Typography type="body2" className={classNames(classes.error, !this.state.error.kr && classes.hide)}>{language.KR.error.require}</Typography>
            <Typography type="body2" className={classNames(classes.error, !this.state.failed && classes.hide)}>{this.state.message}</Typography>
            <TextField id="titleKR" label={language.KR.name} className={classes.textField} value={this.state.service.titleKR} onChange={(event) => this.handleValidate(event,'kr')} onBlur={(event) => this.handleValidate(event,'kr')} required={true} error={this.state.error.titleKR_error} margin="normal"/>
            <TextField id="descriptionKR" rows="5" multiline={true} label="Meta Description (max. length: 200 characters)" className={classes.textField} value={this.state.service.descriptionKR} onChange={(event) => this.handleFieldChange(event)} margin="normal"/>
            <FormLabel className={classes.label_shrink}>{language.KR.summary}</FormLabel>
            <FroalaEditor
              tag='textarea'
              config={editorConfigBasic}
              model={this.state.service.summaryKR}
              onModelChange={(value)=>this.onEditorStateChange('summaryKR',value)}
            />
            <FormLabel className={classes.label_shrink}>{language.KR.detail}</FormLabel>
            <FroalaEditor
              tag='textarea'
              config={editorConfig}
              model={this.state.service.detailKR}
              onModelChange={(value)=>this.onEditorStateChange('detailKR',value)}
            />
            <FormGroup row className={classes.formRow}>
              <Button color="default" onClick={(event) => this.handleCancel(event)}>{language.KR.btnCancel}</Button>
              <Button variant="contained" color="primary" onClick={(event) => this.handleSave(event,'kr')}>{language.KR.btnSave}</Button>
            </FormGroup>
          </form>
        </TabContainer>} */}
      </div>
    );
  }
}

ServiceDetail.propTypes = {
  classes: PropTypes.object.isRequired
};
ServiceDetail.contextTypes = {
  router: PropTypes.object
};

export default withStyles(styles)(ServiceDetail);
