import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import {Typography,Fab,Toolbar, TextField} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import SearchIcon from '@material-ui/icons/Search';

import FormGroup from '@material-ui/core/FormGroup';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import ScheduleIcon from '@material-ui/icons/Schedule';

import classNames from 'classnames';
import ReactTable from './shares/react_table';
import ReactLazyCardMedia from './shares/react_lazy_card';


import theme,{styles} from '../layout/theme';
import Utils from '../api/api';

export class ServiceList extends Component {
  constructor(props){
    super(props);
    this.state = {
      keyword: '',
      searchClick: false
    }
  }

  handleSearch(e){

    var el = document.getElementById('keyword');
    this.setState({ keyword: el.value.trim() });
  }
  handleAdd(){
    this.context.router.history.push('/service/add');
  }
  onSchedule(event){
    //console.log(event);
    let el = event.target.parentNode;
    if(el) {
      let item = el.getAttribute('data-row');
      if(item) {
        item = JSON.parse(item);
        if(item.packagesVN||item.packagesEN) {
          if(item.packagesVN.length||item.packagesEN.length) {
            this.context.router.history.push('/bill/');
          } else {
            this.context.router.history.push('/booking/');
          }
        } else {
          this.context.router.history.push('/booking/');
        }
      }
    }
  }
  render(){
    const $this = this;
    const classes = this.props.classes;
    var source = {
      thead: [{title:'Tên',value:'name'},{title:'SEO/Url',value:'url'},{title:'Hình',value:'photo'},{title:'Ngày cập nhật',value:'date'}],
      tbody: function (n) {
        return ({
          _id: n._id,
          name: n.titleVN,
          url: <div>{n._id}<br/><br/>{n.urlVN}<br/>{n.urlEN}</div>,
          photo: <div className={classes.thumbnailWrapper}><ReactLazyCardMedia className={classes.thumbnail} image={n.image[0] !== '' && n.image[0] !== undefined ? Utils.fixImagePath(n.image[0]) : Utils.fixImagePath('/data/upload/images/no_photo.png',{width:100,height:60})} title={n.titleVN} /></div>,
          date: n.dateModifed
        })
      }
    };

    var schedule = (
      <IconButton className={classes.button} aria-label="Schedule" onClick={(event)=>$this.onSchedule(event)}>
        <ScheduleIcon style={{ fontSize: 20 }}/>
      </IconButton>
    );

    return(
      <div className="list-page">
        <div className={classNames(classes.auto_toggle, this.state.failed && classes.run)}>
          <Typography type="body2">{this.state.message}</Typography>
        </div>
        <Toolbar className={classes.toolbar}>
          <Typography className={classes.heading} variant="h5">Danh mục dịch vụ</Typography>
        </Toolbar>
        <Paper className={classes.paper}>
          <Toolbar className={classes.tableToolbar}>
            <FormGroup className={classes.formRowToolbar}>
              <TextField type="search" id="keyword" label="Tìm kiếm" className={classes.textField + ' form-control-no-bottom'} value={this.state.keyword} onChange={(event)=>this.setState({keyword:event.target.value.trim()})}
              margin="normal"
              InputLabelProps={{
                shrink: true
              }}/>
            </FormGroup>
            <Fab color="primary" aria-label="search" className={classes.button} onClick={(e)=>this.handleSearch(e)}>
              <SearchIcon />
            </Fab>
            <Fab color="primary" aria-label="add" className={classes.button} onClick={()=>this.handleAdd()}>
              <AddIcon />
            </Fab>
          </Toolbar>
          <ReactTable apiActions={[schedule]} apiDuplicate={true} apiList='services' apiSingle='service' apiTable='services' router={this.context.router} dataMap={source} keyword={this.state.keyword} isSearch={this.state.searchClick} >
          </ReactTable>
        </Paper>
      </div>
    )
  }
}

ServiceList.propTypes = {
  classes: PropTypes.object.isRequired,
};
ServiceList.contextTypes = {
  router: PropTypes.object
};

export default withStyles(styles)(ServiceList);
//