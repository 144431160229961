import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import {TextField, Switch,FormControlLabel,FormLabel, FormGroup,FormControl} from '@material-ui/core';
import language from '../layout/language';
import Utils from '../api/api';

import {styles} from '../layout/theme';

import $ from 'jquery';

// Require Editor JS files.
import 'froala-editor/js/froala_editor.pkgd.min.js';
import 'froala-editor/js/third_party/font_awesome.min.js';
import 'froala-editor/js/third_party/ionicons.min.js';


// Require Editor CSS files.
import 'froala-editor/css/froala_style.min.css';
import 'froala-editor/css/froala_editor.pkgd.min.css';

// Require Font Awesome.
import 'froala-editor/css/third_party/font_awesome.min.css';
import 'froala-editor/css/third_party/ionicons.min.css';

import FroalaEditor from 'react-froala-wysiwyg';

class ServicePackageItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      item: {
        _id: 0,
        order: 1,
        title: '',
        subtitle: '',
        summary: '',
        detail: '',
        detailTitle:'',
        isDisabled: false,
        price: 0,
        priceDisplay: '',
        code: '',
        getflyId: ''
      },
      options: {'empty':'empty'}
    };
  }
  componentDidUpdate(prevProps, prevState) {
    if(prevProps.item!==this.props.item){
      var obj = this.props.item;
      this.setState({
        item: obj
      });
    }
  }
  static getDerivedStateFromProps(nextProps, prevState){
    if(nextProps.item!==prevState.item){
      return { item: nextProps.item};
    }
    else return null;
  }
  onEditorStateChange(field,value) {
    var item = this.state.item;

    item[field] = value;

    this.setState({ item: item });
  }
  handleFieldChange(e) {
    var $this = this,
        itemPackage = $this.state.item;
    itemPackage[e.target.id] = e.target.value;

    this.setState(itemPackage,function(){
      //console.log($this.state)
      $this.props.onChangeValue(itemPackage);
    });
  }
  handleColorChange(color,field){
    var $this = this,
        itemPackage = this.state.item;
    itemPackage[field] = color;
    this.setState(itemPackage,function(){
      $this.props.onChangeValue(itemPackage);
    });
  }
  render() {
    const classes = this.props.classes;
    const itemPackage = this.state.item;
    const locale = this.props.locale;
    const l = language[locale];

    var editorConfigBasic = Utils.setupBasicEditor();
    var editorConfig = Utils.setupEditor($,'services',this.state.options);

    return (
      <div className="itemPackage-item">
        <FormGroup className={classes.formRow}>
          <FormControl className={classes.formControl}>
            <TextField id="title" label={l.title} className={classes.textField} value={itemPackage.title} onChange={(event) => this.handleFieldChange(event)} margin="normal" inputProps={{ maxLength: 50 }}/>
          </FormControl>
        </FormGroup>
        <FormGroup className={classes.formRow}>
          <FormControl className={classes.formControl}>
            <TextField id="code" label='Code' className={classes.textField} value={itemPackage.code} onChange={(event) => this.handleFieldChange(event)} margin="normal" inputProps={{ maxLength: 50 }}/>
          </FormControl>
        </FormGroup>
        <FormGroup className={classes.formRow}>
          <FormControl className={classes.formControl}>
            <TextField id="subtitle" label={l.subTitle} className={classes.textField} value={itemPackage.subtitle} onChange={(event) => this.handleFieldChange(event)} margin="normal" inputProps={{ maxLength: 50 }}/>
          </FormControl>
        </FormGroup>
        <FormGroup className={classes.formRowInline}>
          <FormControl className={classes.col1}>
            <TextField id="price" type="number" label={l.price} className={classes.textField} value={itemPackage.price} onChange={(event) => this.handleFieldChange(event)} margin="normal"/>
          </FormControl>
          <FormControl className={classes.col1}>
            <TextField id="priceDisplay" label={l.priceDisplay} className={classes.textField} value={itemPackage.priceDisplay} onChange={(event) => this.handleFieldChange(event)} margin="normal"/>
          </FormControl>
        </FormGroup>
        <FormGroup className={classes.formRow}>
          <FormControl className={classes.formControl}>
            <TextField id="detailTitle" label={l.title + " (accordion)"} className={classes.textField} value={itemPackage.detailTitle} onChange={(event) => this.handleFieldChange(event)} margin="normal" inputProps={{ maxLength: 250 }}/>
          </FormControl>
        </FormGroup>
        <FormGroup className={classes.formRow}>
          <FormControl className={classes.formControl}>
            <FormLabel className={classes.label_shrink}>{l.summary}</FormLabel>
            <FroalaEditor
              tag='textarea'
              config={editorConfigBasic}
              model={itemPackage.summary}
              onModelChange={(value)=>this.onEditorStateChange('summary',value)}
            />
          </FormControl>
        </FormGroup>
        <FormGroup className={classes.formRow}>
          <FormControl className={classes.formControl}>
            <FormLabel className={classes.label_shrink}>{l.detail}</FormLabel>
            <FroalaEditor
              tag='textarea'
              config={editorConfig}
              model={itemPackage.detail}
              onModelChange={(value)=>this.onEditorStateChange('detail',value)}
            />
          </FormControl>
        </FormGroup>
        <FormGroup>
            <FormControlLabel
              control={
                <Switch
                  id="isDisabled"
                  checked={itemPackage.isDisabled}
                  onChange={(event)=>this.handleFieldChange(event)}
                  value={true}
                />
              }
              label="Tạm ngừng"
            />
        </FormGroup>
      </div>
    )
  }
}

ServicePackageItem.propTypes = {
  classes: PropTypes.object.isRequired
};
ServicePackageItem.contextTypes = {
  router: PropTypes.object
};

export default withStyles(styles)(ServicePackageItem);
